<template>
  <div style="margin: 20px">
    <h4>{{ $t("label.paymentMethod") }}</h4>
    <div style="display: flex; margin-top: 20px">
      <a-radio-group
        v-model="value"
        @change="onChange"
        style="border-radius: 15px"
      >
        <a-radio-button
          style="width: 300px; height: 100px; margin: 15px 10px 0 0"
          value="credit"
          class="payment-radio"
        >
          <a-row :gutter="18">
            <a-col :span="8">
              <img
                src="@/assets/images/credit_card.png"
                style="max-width: 60px; margin-top: 15px"
              />
            </a-col>
            <a-col :span="14">
              <h6 style="margin-top: 22px; text-align: center">
                Credit/ Debit Card
              </h6>
            </a-col>
          </a-row>
        </a-radio-button>
        <a-radio-button
          style="width: 300px; height: 100px; margin: 15px 10px 0 0"
          value="bcelonce"
          class="payment-radio"
        >
          <a-row :gutter="18">
            <a-col :span="8">
              <img
                src="@/assets/images/bcelone.png"
                style="max-width: 60px; margin-top: 15px"
              />
            </a-col>
            <a-col :span="14">
              <h6 style="margin-top: 22px; text-align: center">BCELOne Pay</h6>
            </a-col>
          </a-row>
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",

  data() {
    return {
      value: "",
    };
  },

  mounted() {},

  methods: {
    onChange(e) {
      const checked = e.target.value;
      if (checked == "credit") {
        return this.$router.push(`/payment/202304-00005/billing`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-radio:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
